<template>
  <div>
    <div
      v-if="exportLoader"
      class="report-loader-screen"
    >
      <div class="d-flex align-items-center justify-content-center h-100 overdlow-sheet">
        <img
          src="../../../public/loader.gif"
          alt=""
        >
      </div>
    </div>
    <p class="total-hr-timespent">
      Total Hours Spent : <span>{{ totalDailyTime1 | convertMinutes }}</span>
    </p>
    <div class="task-section">
      <time-sheet-loader
        v-if="loader && page == 1"
        :row-num="3"
      />
      <div
        v-else-if="logData.length"
        class="all-time-log"
      >
        <div
          v-for="(log, dateKey) in logData"
          :key="dateKey"
          class="task_detail_block"
        >
          <div
            :id="`dateKey${dateKey}`"
            class="task-detail"
          >
            <div class="d-flex align-items-center justify-content-between">
              <p class="date-time">
                {{ log.date | timeDayFormat }}
                <span>{{ totalLogTime(log.data) }} </span>
              </p>

              <button
                v-if="userInfo.role == 'EMP' && log.data && log.data.length"
                class="download-button"
                @click="downloadCSV(dateKey)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  width="18"
                  height="18"
                />
              </button>
            </div>

            <b-row v-if="log.data.length">
              <b-col cols="12">
                <b-table
                  responsive
                  :fields="fields"
                  :items="log.data"
                  class="feild-name table-responsive scrollable-element-timesheet"
                >
                  <template
                    #cell(task)="data"
                    class="task-column"
                  >
                    <div class="w-full demo word-break ">
                      <div
                        v-if="!showTaskId || showTaskId !== data.item.user_task_id
                        "
                        class="task"
                      >
                        <div>
                          <p class="task-name">
                            <v-clamp
                              autoresize
                              :max-lines="3"
                              ellipsis="..."
                              location="end"
                              :style="{ whiteSpace: 'pre-line' }"
                              class="cursorDefault"
                            >
                              {{ data.item.user_task_title }}
                              <template #after="{ toggle, clamped, expanded }">
                                <button
                                  v-if="clamped == true || expanded == true"
                                  class="see-more-btn"
                                  @click="toggle"
                                >
                                  {{ clamped == true ? "see more" : "see less" }}
                                </button>
                              </template>
                            </v-clamp>
                            <b-badge
                              v-if="data.item.is_manual"
                              class="manualTag cursorDefault"
                            >
                              Manual
                            </b-badge>
                          </p>
                          <div class="d-flex">
                            <p
                              class="project-name d-flex align-items-center cursorDefault"
                              :style="{ color: '#' + data.item.color }"
                            >
                              <feather-icon
                                class="mr-25"
                                icon="FileIcon"
                              />
                              {{ data.item.project_name }}
                            </p>
                            <span
                              v-if="data.item.project_task_number"
                              class="task-link cursorDefault"
                            >
                              <a
                                href="#"
                                class="cursorDefault"
                              >#{{ data.item.code
                              }}{{ data.item.project_task_number }}</a>
                            </span>
                            <p
                              v-if="userInfo &&
                                data.item &&
                                userInfo.id != data.item.user_id
                              "
                              class="project-name d-flex align-items-center"
                            >
                              <feather-icon
                                class="mr-25"
                                icon="UserIcon"
                              />
                              {{ data.item.first_name + ' ' + data.item.last_name }}
                            </p>
                          </div>
                          <div class="d-flex flex-wrap">
                            <b-badge
                              v-for="(tag, tindex) in tagsArray(data.item.tags)"
                              :key="tindex"
                              variant="primary"
                              class="timesheet-body-badge smallBadge cursorDefault"
                            >
                              {{ tag }}
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="row"
                      >
                        <b-col
                          cols="12"
                          xl="6"
                        >
                          <b-form-group class="auto-resize">

                            <textarea
                              :ref="`mytask_${showTaskId}`"
                              v-model="taskTitle"
                              name="task_name"
                              placeholder="Enter Task Description"
                              rows="1"
                              @input="resizeTextarea()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="6"
                        >
                          <b-form-group>
                            <v-select
                              id="project_id"
                              v-model="taskProjectID"
                              name="project_id"
                              label="name"
                              :options="projects"
                              :reduce="(project) => project.id"
                              class="select-size-sm select-project-timesheet-ticket"
                              placeholder="Select Project"
                            />

                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="6"
                        >
                          <b-form-group>
                            <v-select
                              id="ticket_id"
                              v-model="taskTicketID"
                              name="ticket_id"
                              label="number"
                              :options="tickets && tickets.length ? tickets : []
                              "
                              :reduce="(ticket) => ticket.id"
                              class="select-project-timesheet-ticket"
                              placeholder="Select Ticket"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="6"
                        >
                          <b-form-group>
                            <v-select
                              id="tags"
                              v-model="taskTags"
                              hide-details
                              multiple
                              name="tags"
                              label="name"
                              :options="allTags"
                              :reduce="(tag) => tag.name"
                              class="select-tags-timesheet"
                              placeholder="Select Any Tag"
                              :close-on-select="false"
                            />
                          </b-form-group>
                        </b-col>
                      </div>
                    </div>
                  </template>

                  <template
                    #cell(duration)="data"
                    class="task-column"
                  >
                    <div>
                      <div
                        v-if="data.item.count_minutes"
                        style="margin: auto; padding: auto"
                      >
                        {{ data.item.count_minutes | hoursAndMin }}
                      </div>
                    </div>
                  </template>

                  <template
                    #cell(time)="data"
                    class="task-column"
                  >
                    <div
                      v-if="!showTaskId || showTaskId !== data.item.user_task_id
                      "
                      class="mr-2"
                    >
                      <div
                        v-if="data.item.start_time && data.item.end_time"
                        style="margin: auto; padding: auto"
                      >
                        {{ data.item.start_time | timeFormat }} -
                        {{ data.item.end_time | timeFormat }}
                      </div>
                      <div
                        v-else
                        style="margin: auto; padding: auto"
                      >
                        Start At : {{ data.item.start_time | timeFormat }}
                      </div>
                    </div>
                    <validation-observer
                      v-else
                      :ref="`logform${log.id}`"
                    >
                      <b-form class="row mb-2">
                        <div class="d-flex align-items-center">
                          <validation-provider
                            #default="{ errors }"
                            name="Start time"
                            rules="required"
                          >
                            <div class="mb-0 mr-1">
                              <b-form-input
                                v-model="startTime"
                                class="select-item select-time"
                                name="start_time"
                                type="time"
                                placeholder="03:29 PM"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </div>
                          </validation-provider>

                          -
                          <div class="mb-0 ml-1">
                            <validation-provider
                              #default="{ errors }"
                              name="End time"
                              rules="required"
                            >
                              <b-form-input
                                v-model="endTime"
                                class="select-item select-time"
                                name="end_time"
                                type="time"
                                placeholder="03:29 PM"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </div>
                      </b-form>
                    </validation-observer>
                  </template>
                  <template #cell(action)="data">
                    <div v-if="data.item.status !== 'approved' || userInfo.role === 'SA'">
                      <span
                        v-if="!showTaskId || showTaskId !== data.item.user_task_id
                        "
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="21"
                          class="mr-2 edit-icon"
                          @click="editTaskDtl(data.item)"
                        />

                        <feather-icon
                          icon="Trash2Icon"
                          class="trashicon"
                          size="21"
                          @click="deleteConfirmation(data.item.user_task_id)"
                        />
                      </span>
                      <span v-else>
                        <feather-icon
                          icon="CheckIcon"
                          size="21"
                          class="mr-2 text-dark"
                          @click="updateTaskValidation()"
                        />
                        <feather-icon
                          icon="XIcon"
                          class="trashicon"
                          size="21"
                          @click="clearEditTask()"
                        />
                      </span>
                    </div>
                    <div
                      v-else
                      class="ml-2"
                    >
                      <span>-</span>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </div>
        <infinite-loading
          v-if="dataShow"
          ref="teamInfiniteLoading"
          slot="append"
          spinner="spiral"
          :distance="1"
          force-use-infinite-wrapper=".task-section"
          @infinite="timesheetData"
        >
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </div>

      <div
        v-else
        class="w-100"
      >
        <img
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import * as moment from 'moment/moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import html2canvas from 'html2canvas'
import InfiniteLoading from 'vue-infinite-loading'
import VClamp from 'vue-clamp'

export default {
  name: 'Daily',
  components: {
    ValidationProvider,
    ValidationObserver,
    InfiniteLoading,
    VClamp,
  },
  props: {
    startDate: {
      type: String,
      required: true,
      default: null,
    },
    endDate: {
      type: String,
      required: true,
      default: null,
    },
    userID: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    projectID: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    tags: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    allTags: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    projects: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    manageBy: {
      type: String,
      required: false,
      default: null,
    },
    reportingTo: {
      type: String,
      required: false,
      default: null,
    },
    groupId: {
      type: String,
      required: false,
      default: null,
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },

    appendToBody: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      input: {},
      loader: false,
      task: null,
      html2canvas,
      fields: [
        {
          key: 'task',
          label: 'task',
          tdClass: 'task-project-view',
          thStyle: { minWidth: '300px' },
        },

        {
          key: 'time',
          label: ' time',
          thStyle: { width: '300px' },
          tdClass: 'task-item',
        },
        {
          key: 'duration',
          label: 'duration',
          thStyle: { width: '200px' },
          tdClass: 'task-item-action',
        },

        {
          key: 'action',
          label: ' action',
          thStyle: { width: '100px' },
          tdClass: 'task-item-action',
        },
      ],
      logData: [],
      startTime: null,
      endTime: null,
      showTaskId: null,
      taskTitle: null,
      taskTags: null,
      existingTaskTags: null,
      taskProjectID: null,
      taskDate: null,
      taskTicketID: null,
      taskUserID: null,
      tickets: null,
      load_tickets: false,
      exportLoader: false,
      per_page: 50,
      taskCount: null,
      dataShow: true,
      isFirst: true,
      totalDailyTime1: null,
    }
  },
  computed: {
    totalDailyTime() {
      let totalSeconds = 0

      this.logData.forEach(dateData => {
        dateData.data.forEach(data => {
          if (data && data.start_time && data.end_time) {
            totalSeconds += data.total_time
          }
        })
      })

      return totalSeconds > 0 ? this.secondsToTime(totalSeconds * 60) : '00:00'
    },
  },

  watch: {
    taskProjectID(nv) {
      if (nv) {
        this.taskTicketID = null
        this.getProjectTasksData(nv)
      }
    },
  },
  mounted() {
    eventBus.$on('applyDate', data => {
      if (data) {
        this.isFirst = true
      }
    })
    eventBus.$on('loadTaskList', data => {
      if (data) {
        this.page = 1
        this.logData = []
        this.timesheetData()
      }
    })
    eventBus.$on('taskListDailyLoad', data => {
      if (data) {
        this.logData = []
        this.timesheetData()
        this.isFirst = false
      }
    })
  },
  destroyed() {
    eventBus.$off('taskListDailyLoad')
    eventBus.$off('loadTaskList')
    eventBus.$off('applyDate')
  },
  methods: {

    // get tags
    tagsArray(data) {
      const tags = data.split(',')
      return tags
    },

    editTaskDtl(task) {
      this.taskProjectID = task.user_task_project_id
      setTimeout(() => {
        this.taskTicketID = task.project_task_id
      }, 500)
      this.taskTitle = task.user_task_title
      this.showTaskId = task.user_task_id
      this.taskDate = task.date ? task.date : null
      this.taskUserID = task.user_id
      this.startTime = moment(`${task.date} ${task.start_time}`).format(
        'HH:mm',
      )
      this.endTime = moment(`${task.date} ${task.end_time}`).format('HH:mm')
      if (task.tags) {
        const tag = this.tagsArray(task.tags)
        const tags = []
        tag.forEach(tag => {
          tags.push(tag)
        })
        this.taskTags = tags
      }
      /*  hide scroll */
      setTimeout(() => {
        const textarea = this.$refs[`mytask_${this.showTaskId}`][0]
        textarea.style.overflow = textarea.scrollHeight >= 214 ? 'auto' : 'hidden'
        textarea.style.height = `${textarea.scrollHeight}px`
      }, 100)
    },
    /**
     * Get Projects tasks details
     */
    async getProjectTasksData(id) {
      this.load_tickets = true
      const input = {
        project_id: id,
      }
      const response = await this.getHTTPPostResponse(
        'user-task/ticket-lists',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.tickets = data.project_tickets && data.project_tickets.length
          ? data.project_tickets
          : []
        this.load_tickets = false
      }
    },
    clearEditTask() {
      this.showTaskId = null
      this.taskTitle = null
      this.taskDate = null
      this.taskProjectID = null
      this.taskTicketID = null
      this.taskTags = []
      this.existingTaskTags = []
      this.startTime = null
      this.endTime = null
      this.taskUserID = null
    },

    /* Validate updated Task */
    updateTaskValidation() {
      if (!this.taskTitle) {
        return false
      } if (!this.taskProjectID) {
        return false
      }
      if (this.taskTags && !this.taskTags.length) {
        return false
      }
      if (!this.startTime) {
        return false
      } if (!this.endTime) {
        return false
      }
      const validateTime = this.validateTime(this.startTime, this.endTime)
      if (validateTime) {
        this.updateTask()
      }
    },
    /**
     * update timer details
     */
    async updateTask() {
      const tags = []
      this.logData = []
      if (this.taskTags && this.taskTags.length) {
        this.taskTags.forEach(element => {
          tags.push({ tag: element })
        })
      }
      const input = {
        user_task_id: this.showTaskId,
        project_id: this.taskProjectID,
        user_id: this.taskUserID,
        project_task_id: this.taskTicketID,
        title: this.taskTitle,
        date: moment(this.taskDate).format('YYYY-MM-DD'),
        start_time: moment(`${this.taskDate}T${this.startTime}`).format(
          'HH:mm:ss',
        ),
        end_time: moment(`${this.taskDate}T${this.endTime}`).format('HH:mm:ss'),
        tags,
        remove_tag_ids:
          this.existingTaskTags && this.existingTaskTags.length
            ? this.existingTaskTags.map(t => t.id)
            : [],
        is_edit_from_timesheet: true,
      }
      const response = await this.getHTTPPostResponse(
        'user-task/task',
        input,
        true,
      )
      // if (response && response.status == 200) {
      this.page = 1
      this.clearEditTask()
      this.timesheetData()
      // }
    },

    async deleteConfirmation(taskID, logID = null) {
      const value = await this.conformationAlert()
      if (value.isConfirmed == true) {
        this.logData = []
        this.deleteTaskLog(taskID, logID)
      }
    },

    totalLogTime(data) {
      const totalTimeInSeconds = data.reduce((total, entry) => {
        if (entry && entry.start_time && entry.end_time) {
          return total + entry.total_time
        }
        return total
      }, 0)

      return totalTimeInSeconds ? this.secondsToTime(totalTimeInSeconds * 60) : '00:00'
    },

    /**
     * Get daily timesheet data
     */
    async timesheetData($states) {
      this.loader = true
      const input = {
        member: this.userID && this.userID.length ? this.userID : [],
        project: this.projectID && this.projectID.length ? this.projectID : [],
        start_date: this.startDate
          ? this.startDate
          : moment().startOf('day').format('YYYY-MM-DD'),
        end_date: this.endDate
          ? this.endDate
          : moment().startOf('day').format('YYYY-MM-DD'),
        tags: this.tags,
        group_id: this.groupId,
        manage_by: this.manageBy,
        reporting_to: this.reportingTo,
        page: this.page,
        per_page: this.per_page,
      }

      this.input = input
      const response = await this.getHTTPPostResponse(
        'user-task/daily-task',
        input,
        false,
      )
      if (response && response.status == 200) {
        this.taskCount = response.data.count
        this.totalDailyTime1 = response.data.totalTime

        const data = response.data.user_tasks

        for (const property in data) {
          const index = this.logData.findIndex(
            element => element.date == property,
          )
          if (index > -1) {
            this.logData[index].data = [
              ...this.logData[index].data,
              ...data[property],
            ]
          } else {
            const datapro = data[property].filter(data => data.end_time)

            this.logData.push({
              date: property,
              data: datapro,
            })
          }
        }

        this.loader = false
        if (this.taskCount >= this.page * this.per_page) {
          this.dataShow = true
          this.page += 1
          $states.loaded()
        } else {
          this.dataShow = false
        }
      } else {
        this.loader = false
      }
    },

    /**
     * delete tag
     */
    async deleteTaskLog(taskID, logID) {
      let url = `user-task/task/${taskID}/delete`
      if (logID) {
        url = `${url}/${logID}`
      }
      const response = await this.getHTTPDeleteResponse(url, {}, true)
      if (response && response.status === 200) {
        this.page = 1
        this.timesheetData()
      }
    },
    async downloadCSV(date) {
      const imageName = moment(new Date()).format('DD-MM-YYYY')
      const className = `dateKey${date}`
      this.html2canvas(document.getElementById(className)).then(canvas => {
        const image = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream') // Convert image to 'octet-stream' (Just a download, really)
        const a = document.createElement('a')
        a.href = image
        a.download = `${imageName}.png`
        a.click()
      })
    },

    /* temp text-area  */
    resizeTextarea() {
      const textarea = this.$refs[`mytask_${this.showTaskId}`][0]
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
      if (textarea.scrollHeight >= 214) {
        textarea.style.overflow = 'auto'
      } else {
        textarea.style.overflow = 'hidden'
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/viewbody.css";

.download-button {
  background-color: #2178fb !important;
  display: flex;
  align-items: center;
  padding: 5px;
  color: #fff;
  border: 1px solid #2178fb;
  border-radius: 5px;
}

.download-button:hover,
.download-button:focus,
.download-button:active {
  background-color: #2178fb !important;
}

.report-loader-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1080;

  img {
    width: 100px;
  }

  .overdlow-sheet {
    overflow-y: hidden;
  }
}

.manualTag {
  background-color: rgba(35, 184, 114, 0.12);
  border-radius: 20px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #23b872;
  cursor: default !important;
}

.see-more-btn {
  background-color: transparent;
  border-color: transparent;
  color: #2178fb !important;
  border: 1px solid transparent;
}

.task-detail .feild-name .table .select-tags-timesheet .vs__selected {
  background-color: #2178fb;
  border: transparent;
  padding: 0px 5px;
}

.task-name {
  span {
    cursor: default !important;
  }
}

.auto-resize {
  textarea {
    resize: none;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #888;
    padding: 0.5rem;
    border: 1px solid #dddbe2;
    border-radius: 5px;
    min-height: 38px;
    max-height: 214px;

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #bdbdbd;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 20px;
      }
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 20px;
      background-color: #b8b8b8;
    }

    &:focus {
      border: 1px solid #2178fb;
      box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
    }

    &:focus-visible {
      border: none;
      box-shadow: none;
      background-color: transparent;
      outline: 1px solid #2178fb;
    }
  }
}
</style>
