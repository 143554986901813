<template>
  <div class="weekly-mainsection">
    <time-sheet-loader
      v-if="loader"
      :row-num="2"
    />

    <div
      v-else-if="weekData.length"
      class="weekly-timesheet-calendar"
    >
      <table>
        <tr>
          <th class="table-title">
            Weekly Timesheet
          </th>
          <th
            v-for="(date, dateKey) in weekDates"
            :key="dateKey"
          >
            <div
              v-if="!checkLeaveOrNot(date)"
              class="table-head"
            >
              <span>{{ date | getDayOnly }}</span>
              <div>
                <p>{{ date | getDayNameOnly }}</p>
                <p class="month">
                  {{ date | getMonthOnly }}
                </p>
              </div>
            </div>
            <div
              v-else
              class="table-head w-180"
            >
              <span>
                <img
                  src="../../assets/images/Image/holiday.png"
                  width="50px"
                >
                {{ date | getDayOnly }}
              </span>
              <div>
                <p>{{ date | getDayNameOnly }}</p>
                <p class="month">
                  {{ date | getMonthOnly }}
                </p>
              </div>
            </div>
          </th>
          <th class="table-title">
            Total
          </th>
        </tr>
        <!-- weekDates -->
        <tr
          v-for="(data, index) in weekData"
          :key="index"
        >
          <td>
            <div class="projects-list">
              <b-avatar
                v-if="data.project_name"
                variant="primary"
                size="50"
                :text="data.project_name | avatarText"
                :style="{ 'background-color': '#' + data.color }"
                class="avtar"
              />
              <div
                v-if="data.project_name"
                class="project-data"
              >
                <p class="project-name">
                  {{ data.project_name }}
                </p>

                <!-- project activity -->
                <p
                  v-if="data.work_type == 'productive'"
                  class="project-activity"
                >
                  project work
                </p>
                <!-- non project activity label -->
                <p
                  v-else
                  class="non-projectactivity"
                >
                  Non Project work
                </p>
              </div>
              <div
                v-else
                class="project-data"
              >
                <p class="project-name">
                  All projects
                </p>
              </div>
            </div>
          </td>
          <td
            v-for="(week_date, windex) in data.records"
            :key="windex"
            :class="(data.work_type == 'productive' || data.work_type == 'non_productive') && week_date.totalTime ? 'cursor-pointer' : 'cursorDefault'"
          >
            <span
              v-if="week_date.totalTime"
              @click="redirectToProjectTimeSheet(index, windex)"
            >
              {{ week_date.totalTime }}</span>
            <span v-else>-</span>
          </td>
          <td class="total-timerecoard">
            <p
              v-if="index == weekData.length - 1"
              :class="
                totalWeeklyTimeHours >= 40
                  ? 'text-total-time'
                  : 'text-low-total-time'
              "
            >
              {{ data.total }}
            </p>
            <p v-else-if="data.total">
              {{ data.total }}
            </p>
            <p v-else>
              -
            </p>
          </td>
        </tr>
        <tr class="allproject-total">
          <td>
            <p
              class="project-name"
              style="margin-left: 10px"
            >
              Clock In/Out
            </p>
          </td>
          <td
            v-for="(date, dateKey) in weekDates"
            :key="dateKey"
          >
            <p>{{ getTotalTimeDateWise(date) }}</p>
          </td>
          <td>
            <p>
              {{ totalWeeklyClockInOutTime | secondsToTime }}
            </p>
          </td>
        </tr>
      </table>
    </div>

    <div
      v-else
      class="w-100"
    >
      <img
        src="@/assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img"
      >
    </div>
    <div class="d-flex justify-content-end">
      <button
        v-if="
          userInfo.role == 'EMP' && isSubmitTimeBtnVisible
        "
        :class="
          submitTimeSheetLoader || totalWeeklyTimeHours < 30
            ? 'submitButtonCursor submit-sheet'
            : 'submit-sheet'
        "
        :disabled="submitTimeSheetLoader"
        @click="submitTimeSheet()"
      >
        <b-spinner
          v-if="submitTimeSheetLoader"
          small
          label="Small Spinner"
        />
        Submit Timesheet
      </button>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { eventBus } from '@/main'
import * as moment from 'moment/moment'

export default {
  name: 'Weekly',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    startDate: {
      type: String,
      required: false,
      default: null,
    },
    endDate: {
      type: String,
      required: false,
      default: null,
    },
    userID: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    projectID: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    manageBy: {
      type: String,
      required: false,
      default: null,
    },
    reportingTo: {
      type: String,
      required: false,
      default: null,
    },
    groupId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loader: true,
      weekDates: [],
      weekData: [],
      leaveList: [],
      leaveIndex: [],
      submitTimeSheetLoader: false,
      clockInOutTime: [],
      submittedTimeSheetData: [],
      totalWeeklyClockInOutTime: null,
    }
  },
  computed: {
    totalWeeklyTimeHours: {
      get() {
        return this.weekData.reduce((totalMinutes, element) => {
          const time = element.total ? element.total.split(':').map(Number) : [0, 0]
          return totalMinutes + (time[0] * 60 + time[1])
        }, 0) / 60
      },
    },

    isSubmitTimeBtnVisible() {
      const startOfWeek = moment(this.startDate).isoWeekday(1).format('YYYY-MM-DD')
      /* current week not show btn requirement given by  ba & qa */
      const currentWeek = moment().isoWeekday(1).format('YYYY-MM-DD')
      const endOfWeek = moment(this.startDate).isoWeekday(7).format('YYYY-MM-DD')
      return startOfWeek !== currentWeek && this.startDate === startOfWeek && this.endDate === endOfWeek
           && !this.submittedTimeSheetData.some(s => s.start_date === this.startDate)
    },

    dateWiseTotals() {
      const dateTotals = {}
      this.clockInOutTime.forEach(user => {
        user.clock.forEach(entry => {
          dateTotals[entry.in_date] = (dateTotals[entry.in_date] || 0) + entry.time_diff
          this.totalWeeklyClockInOutTime += entry.time_diff
        })
      })
      return dateTotals
    },
  },

  /*  fix double api calling */
  // watch: {
  //   startDate(nv) {
  //     if (nv) {
  //       this.weekDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)
  //       this.timeSheetData()
  //       this.getClockInOutTime()
  //       this.updateLeaveInbox()
  //     }
  //   },
  // },
  mounted() {
    this.getLeaveList()
    this.getTimeSheetStatus()
    if (this.startDate && this.endDate) {
      this.weekDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)
      this.timeSheetData()
      this.getClockInOutTime()
    }
    eventBus.$on('taskListWeeklyLoad', data => {
      if (data) {
        this.weekDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)
        this.timeSheetData()
        this.getClockInOutTime()
        this.updateLeaveInbox()
      }
    })
  },
  destroyed() {
    eventBus.$off('taskListWeeklyLoad')
  },

  methods: {
    /** check leave or not function start */
    checkLeaveOrNot(date) {
      let check = false

      if (this.leaveIndex && this.leaveIndex.length) {
        const matched = this.leaveIndex.filter(e => e.includes(date))

        if (matched > -1) {
          check = false
        } else {
          check = true
        }
      }
      return check
    },
    updateLeaveInbox() {
      this.leaveIndex = this.weekDates.filter(e => this.leaveList.some(
        element => element.date.toLowerCase() === e.toLowerCase(),
      ))
    },
    async getLeaveList() {
      const input = {
        sort_field: 'date',
        sort_order: 'asc',
        date: moment(this.startDate).startOf('day').format('YYYY-MM-DD'),
      }

      const url = `${process.env.VUE_APP_HRMS_SERVICE_URL}holiday-list`
      await this.$axios
        .post(url, input)
        .then(response => {
          this.leaveList = response.data.data.holidays
          this.updateLeaveInbox()
        })
    },
    /** check leave or not function end */

    redirectToProjectTimeSheet(projectIndex, weekDatesIndex) {
      const weekDataLength = this.weekData.length
      const weekDate = this.weekDates[weekDatesIndex]
      if (projectIndex < weekDataLength - 1) {
        this.$store.state.app.dailyFilterDate = weekDate
        this.$store.state.app.dailyFilterProjectId = this.weekData[projectIndex]?.project_id || null
        this.$store.state.app.weeklyEndDate = this.endDate
        this.$store.state.app.weeklyStartDate = this.startDate
        this.$emit('tabChange')
      }
    },

    /**
     * Get weekly timeSheet data
     */
    async timeSheetData() {
      this.loader = true
      const input = {
        member: this.userID && this.userID.length ? this.userID : null,
        project: this.projectID && this.projectID.length ? this.projectID : [],
        start_date: this.startDate,
        end_date: this.endDate,
        group_id: this.group_id,
        manage_by: this.manage_by,
        reporting_to: this.reporting_to,
      }
      const response = await this.getHTTPPostResponse(
        'user-task/weekly-task',
        input,
        false,
      )
      if (response && response.status == 200) {
        const resData = response.data && response.data[0] ? response.data[0] : []
        const weekData = []

        Object.values(resData).forEach(project => {
          const data = {
            project: null,
            records: null,
            total: null,
          }

          Object.values(project).forEach((value, index) => {
            if (index == 0 && value[index]) {
              data.project_id = value[index].project_id
              data.project_name = value[index].projectName
              data.color = value[index].color
              data.work_type = value[index].work_type
              data.code = value[index].code
            }
          })
          const timeTotal = this.getProjectTimes(project)
          data.records = timeTotal
          data.total = this.getProjectWeekTotal(timeTotal)

          weekData.push(data)
        })
        if (weekData && weekData.length) {
          const allDateTime = this.getAllProjectTotalTimes(weekData)
          const lastObj = {
            project: null,
            records: allDateTime,
            total: this.getAllProjectWeekTotal(allDateTime),
          }
          weekData.push(lastObj)
        }

        this.weekData = weekData
      }
      this.loader = false
    },

    getProjectTimes(data) {
      const logDatas = []
      const currDate = moment(this.startDate).startOf('day')
      const lastDate = moment(this.endDate).startOf('day')
      for (let i = 0; currDate.diff(lastDate) <= 0; i++) {
        const date = currDate.clone().format('YYYY-MM-DD')
        if (data[date]) {
          let total = 0
          data[date].forEach(element => {
            if (element && element.count_minutes) {
              const startTime = moment(
                `${element.date} ${element.start_time}`,
              ).format('YYYY-MM-DD HH:mm:ss')

              // count time
              const endDate = moment(`${element.date} ${element.end_time}`) // todays date
              const duration = moment.duration(endDate.diff(startTime))
              const time = duration.asSeconds()
              total += time
            }
          })
          logDatas.push({
            date,
            totalTime: this.secondsToTime(total),
            totalSecond: total,
          })
        } else {
          logDatas.push({
            date,
            totalTime: null,
            totalSecond: 0,
          })
        }
        currDate.add(1, 'days')
      }
      //
      return logDatas
    },
    getProjectWeekTotal(data) {
      let total = null
      data.forEach(element => {
        total += element.totalSecond
      })
      return total ? this.secondsToTime(total) : null
    },
    getAllProjectTotalTimes(data) {
      const logDatas = []
      const currDate = moment(this.startDate).startOf('day')
      const lastDate = moment(this.endDate).startOf('day')
      for (let i = 0; currDate.diff(lastDate) <= 0; i++) {
        const date = currDate.clone().format('YYYY-MM-DD')
        let total = 0
        data.forEach(element => {
          const recd = element.records.find(r => r.date == date)
          if (recd) {
            total += recd.totalSecond
          }
        })
        logDatas.push({
          date,
          totalTime: total ? this.secondsToTime(total) : null,
          totalSecond: total || 0,
        })
        currDate.add(1, 'days')
      }
      return logDatas
    },
    getAllProjectWeekTotal(data) {
      let total = null
      data.forEach(element => {
        total += element.totalSecond
      })
      return total ? this.secondsToTime(total) : null
    },
    async submitTimeSheet() {
      this.submitTimeSheetLoader = true
      const input = {
        start_date: this.startDate,
        end_date: this.endDate,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/submit-timesheet',
        input,
        true,
      )
      if (response && response.status === 200) {
        this.submitTimeSheetLoader = false
        this.getTimeSheetStatus()
        eventBus.$emit('reloadPendingTimesheetData')
      }
      this.submitTimeSheetLoader = false
    },

    /**
     * get user weekly clock in out time
     */
    async getClockInOutTime() {
      const input = {
        start_date: this.startDate,
        end_date: this.endDate,
        member:
          this.userID && this.userID.length ? this.userID : [this.userInfo.id],
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/dailyTimeSheet',
        input,
      )
      if (response && response.status === 200) {
        this.clockInOutTime = response.data.users
        this.totalWeeklyClockInOutTime = 0
      }
    },

    /**
     * Get getTimeSheetStatus
     */
    async getTimeSheetStatus() {
      this.submitTimeSheetLoader = true
      const input = {
        approval_status: ['approved', 'in_process'],
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/user-all-timesheet',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.timesheet
        this.submittedTimeSheetData = data
      }
      this.submitTimeSheetLoader = false
    },

    getTotalTimeDateWise(date) {
      const index = Object.keys(this.dateWiseTotals).indexOf(date)
      const values = Object.values(this.dateWiseTotals)

      if (index !== -1) {
        const sec = values[index]
        return this.secondsToTime(sec)
      }
      return '-'
    },
  },
}
</script>

<style>
@import "../../assets/scss/component-css/viewbody.css";
.text-total-time {
  color: #2178fb;
}
.text-low-total-time {
  color: #fb5362;
}
.submitButtonCursor {
  cursor: not-allowed;
}
.w-180 {
  width: 150px;
}
</style>
