<template>
  <div class="clock-in-out">
    <div class="viewEdit">
      <b-card class="mb-0 timesheet-header">
        <!--view edit header -->
        <div class="card-header-edit">
          <p class="title">
            Task Time Log
          </p>
          <div class="card-header-second mt-0">
            <div class="calendar mt-0">
              <custom-calender
                :start="startDate"
                :end="endDate"
                :date-range-config="dateRangeConfig"
                :active-btn="activeBtn"
                :from="tab"
                @date="updateDate"
              />
            </div>
            <div class="button-group">
              <b-button-group>
                <b-button
                  :class="[
                    tab ==='daily'
                      ? 'button-group-item active'
                      : 'button-group-item',
                  ]"
                  @click="tab = 'daily'"
                >Daily</b-button>
                <b-button
                  :class="[
                    tab ==='weekly'
                      ? 'button-group-item-weekly active'
                      : 'button-group-item-weekly',
                  ]"
                  @click="tab = 'weekly'"
                >Weekly</b-button>
              </b-button-group>
              <Help
                v-show="$store.state.app.userManualList"
                :code="userInfo.role ==='SA' ? 'TaskTimeLog' : 'TimesheetManage'"
              />
            </div>
          </div>
          <button class="add-time d-none">
            Add time
          </button>
        </div>
      </b-card>

      <div class="card-filter-section">
        <b-row>
          <b-col
            cols="8"
            lg="9"
            class="d-block d-lg-flex align-items-center justify-content-start flex-wrap"
          >
            <p class="filter-title">
              Filters:
            </p>
            <div
              v-if="
                (userInfo && userInfo.role ==='SA') ||
                  (userInfo.users && userInfo.users.length) ||
                  (userInfo.manage_by && userInfo.manage_by.length)
              "
              class="w-lg-100"
            >
              <Custom-drop-down
                v-model="searchUser"
                :options="filterUserOptions"
                multiple
                label="Members"
                icon="EditIcon"
              />
            </div>
            <div>
              <Custom-drop-down
                v-model="searchProjects"
                :options="filterProjectOptions"
                label="Project"
                icon="EditIcon"
                multiple
              />
            </div>
            <div v-if="tab ==='daily'">
              <Custom-drop-down
                v-model="searchTags"
                :options="filterTagsOptions"
                multiple
                label="Tag"
                icon="EditIcon"
              />
            </div>
            <div
              v-if="userInfo.role ==='SA'"
              class=""
            >
              <Custom-drop-down
                v-model="manage_by"
                :options="manage_by_users"
                label="Managed by"
                icon="EditIcon"
              />
            </div>

            <div
              v-if="userInfo.role ==='SA'"
              class=""
            >
              <Custom-drop-down
                v-model="reporting_to"
                :options="reporting_to_users"
                label="Reporting to"
                icon="EditIcon"
              />
            </div>

            <div
              v-if="userInfo.role ==='SA'"
              class=""
            >
              <Custom-drop-down
                v-model="group_id"
                :options="groups"
                label="Group"
                icon="EditIcon"
              />
            </div>
          </b-col>
          <b-col
            cols="4"
            lg="3"
            style="text-align: end"
          >
            <button
              class="apply-filter filterRedesign mr-1"
              @click="ApplyFilter()"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign"
              @click="clearFilter()"
            >
              Clear
            </button>
          </b-col>
        </b-row>
      </div>

      <!-- Daily timesheet data -->
      <div v-if="tab ==='daily'">
        <Daily
          ref="dailyLog"
          :start-date="startDate"
          :end-date="endDate"
          :user-i-d="user_id"
          :project-i-d="project_id"
          :tags="tags"
          :projects="projects"
          :all-tags="$store.state.app.tagList"
          :group-id="group_id"
          :manage-by="manage_by"
          :reporting-to="reporting_to"
          :page="page"
        />
      </div>
      <!-- weekly timesheet data -->
      <Weekly
        v-if="tab ==='weekly'"
        ref="weeklyLog"
        :start-date="startDate"
        :end-date="endDate"
        :user-i-d="user_id"
        :project-i-d="project_id"
        :tags="tags"
        :group-id="group_id"
        :manage-by="manage_by"
        :reporting-to="reporting_to"
        :page="page"
        @tabChange="tab = 'daily'"
      />
    </div>
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue'
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import CustomDropDown from '@/components/dropdown/customDropdown.vue'
import Weekly from './WeeklyView.vue'
import Daily from './DailyView.vue'

export default {
  name: 'ViewEditHeader',
  components: {
    Daily,
    Weekly,
    CustomDropDown,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      tab: 'daily',
      startDate: moment().startOf(this.tab === 'daily' ? 'day' : 'week').format('YYYY-MM-DD'),
      endDate: moment().endOf(this.tab === 'daily' ? 'day' : 'week').add(1, 'd').format('YYYY-MM-DD'),
      users: [],
      projects: [],
      user_id: null,
      project_id: [],
      dateRangeConfig: {
        maxDate: new Date(),
        mode: 'range',
        inline: true,
      },
      searchProjects: [],
      searchUser: [],
      searchTags: [],
      tags: [],
      manage_by: null,
      reporting_to: null,
      reporting_to_users: [],
      manage_by_users: [],
      userIds: [],
      group_id: null,
      groups: [],
      page: 1,
      weeklyFlag: false,
      activeBtn: null,
    }
  },
  computed: {
    filterUserOptions() {
      return this.sortedUser(this.users)
    },
    filterTagsOptions() {
      const tags = []
      this.$store.state.app.tagList.forEach(element => {
        tags.push({
          value: element.name,
          label: element.name,
        })
      })
      return tags
    },
    filterProjectOptions() {
      const allProject = []
      this.projects.forEach(element => {
        allProject.push({
          value: element.id,
          label: element.name,
          type: element.work_type,
        })
      })
      // Sort the allProject array alphabetically by project name (label)
      allProject.sort((a, b) => a.label.localeCompare(b.label))
      return allProject
    },
  },
  watch: {
    manage_by() {
      this.searchUser = []
      this.timeSheetUserList()
    },
    reporting_to() {
      this.timeSheetUserList()
    },

    startDate(nv) {
      if (nv) {
        this.page = 1
      }
    },
    tab(nv) {
      this.page = 1
      this.activeBtn = null
      const state = this.$store.state.app

      const getFormattedDate = date => moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')

      const updateDates = date => {
        this.startDate = getFormattedDate(date)
        this.endDate = getFormattedDate(date)
      }

      const setDailyFilter = () => {
        if (state.dailyFilterDate && state.dailyFilterProjectId) {
          updateDates(state.dailyFilterDate)
          this.searchProjects = [state.dailyFilterProjectId]
        } else {
          this.startDate = moment().startOf('day').format('YYYY-MM-DD')
          this.endDate = moment().endOf('day').format('YYYY-MM-DD')
        }
        this.ApplyFilter()
      }

      const setWeeklyFilter = () => {
        if (this.$route.query && typeof this.$route.query.startDate !== 'undefined' || this.weeklyFlag) {
          this.startDate = this.$route.query && this.$route.query.startDate ? this.$route.query.startDate : this.startDate
          this.endDate = this.$route.query && this.$route.query.endDate ? this.$route.query.endDate : this.endDate
          if (!this.weeklyFlag) {
            this.$router.replace({ name: 'timeSheetView' })
          }
        } else {
          state.dailyFilterDate = null
          state.dailyFilterProjectId = null
          if (state.weeklyStartDate && state.weeklyEndDate) {
            updateDates(state.weeklyStartDate)
            this.endDate = getFormattedDate(state.weeklyEndDate)
            state.weeklyStartDate = null
            state.weeklyEndDate = null
          } else {
            this.startDate = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD')
            this.endDate = moment().endOf('week').add(1, 'days').format('YYYY-MM-DD')
          }
        }
      }

      if (nv) {
        if (nv === 'daily') {
          setDailyFilter()
        } else {
          setWeeklyFilter()
        }
      }
    },

    project_id(nv) {
      if (this.userInfo.role === 'SA' && !nv.length) {
        this.timeSheetUserList()
      } else {
        this.reportingAndManageByUser()
      }
    },

    searchProjects(nv) {
      this.searchUser = []
      if (!nv.length && this.userInfo.role === 'SA') {
        this.timeSheetUserList()
      } else {
        this.reportingAndManageByUser()
      }
    },
  },
  mounted() {
    const state = this.$store.state.app
    /* check start & end date store in timeSheetRejectDates */
    if (
      state.timeSheetRejectDates.start_date
      && state.timeSheetRejectDates.end_date
    ) {
      this.startDate = moment(
        state.timeSheetRejectDates.start_date,
        'YYYY-MM-DD',
      ).format('YYYY-MM-DD')
      this.endDate = moment(
        state.timeSheetRejectDates.end_date,
        'YYYY-MM-DD',
      ).format('YYYY-MM-DD')
      state.timeSheetRejectDates.start_date = null
      state.timeSheetRejectDates.end_date = null
    } else {
      /* check in route have project id */
      if (this.$route.params && this.$route.params.projectId) {
        this.searchProjects = [this.$route.params.projectId]
      }
      if (
        this.$route.query
        && typeof this.$route.query.startDate !== 'undefined'
      ) {
        this.tab = 'weekly'
      }
      this.startDate = moment().startOf('day').format('YYYY-MM-DD')
      this.endDate = moment().endOf('day').format('YYYY-MM-DD')
    }

    eventBus.$on('timeSheetDateSet', data => {
      if (data) {
        this.tab = 'weekly'
        this.weeklyFlag = true
        this.startDate = data.startDate
        this.endDate = data.endDate
      }
    })
    if (state.tagList && state.tagList.length === 0) {
      this.getTagsData()
    }
    this.ApplyFilter()
    this.filterFunctionCallFromMixin()

    if (this.userInfo.role === 'SA') {
      this.timeSheetUserList()
    } else {
      this.reportingAndManageByUser()
    }
  },
  destroyed() {
    eventBus.$off('timeSheetDateSet')
  },
  methods: {
    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null
      this.endDate = obj && obj.endDate ? obj.endDate : null
      this.activeBtn = obj && obj.active ? obj.active : null
      this.ApplyFilter()
    },

    /*  filter data */
    async filterFunctionCallFromMixin() {
      if (this.userInfo && this.userInfo.role === 'SA') {
        if (this.$store.state.app.groupList.length === 0) {
          this.groups = await this.getGroupData()
        } else {
          this.groups = this.$store.state.app.groupList
        }
      }

      if (this.$store.state.app.manageByUsersList.length === 0) {
        this.manage_by_users = await this.manageByUserList()
      } else {
        this.manage_by_users = this.$store.state.app.manageByUsersList
      }

      if (this.$store.state.app.reportingUserList.length === 0) {
        this.reporting_to_users = await this.reportingtoUserList()
      } else {
        this.reporting_to_users = this.$store.state.app.reportingUserList
      }
      this.projects = await this.projectList()
    },

    ApplyFilter() {
      if (this.userInfo.role === 'SA') {
        if (this.reporting_to || this.manage_by) {
          if (this.searchUser && this.searchUser.length) {
            this.user_id = this.searchUser
          } else {
            this.user_id = []
          }
        } else {
          this.user_id = this.searchUser
        }
      } else {
        this.user_id = this.searchUser
      }

      this.project_id = this.searchProjects
      this.tags = this.searchTags
      setTimeout(() => {
        if (this.tab === 'daily') {
          eventBus.$emit('taskListDailyLoad', true)
        } else {
          eventBus.$emit('taskListWeeklyLoad', true)
        }
      }, 150)
      this.page = 1
    },
    clearFilter() {
      this.group_id = null
      this.searchProjects = []
      this.searchUser = []
      this.searchTags = []
      this.user_id = []
      this.project_id = []
      this.tags = []
      this.manage_by = !this.manage_by ? this.manage_by : null
      this.reporting_to = !this.reporting_to ? this.reporting_to : null
      this.page = 1
      this.startDate = this.tab === 'daily' ? moment().startOf('day').format('YYYY-MM-DD') : moment().startOf('week').add(1, 'd').format('YYYY-MM-DD')
      this.endDate = this.tab === 'daily' ? moment().endOf('day').format('YYYY-MM-DD') : moment().endOf('week').add(1, 'd').format('YYYY-MM-DD')
      this.ApplyFilter()
      eventBus.$emit('applyDate', 'applyDate')
    },

    /* user List */
    async timeSheetUserList() {
      this.userIds = []
      const input = {
        is_active: 1,
        manage_by: this.manage_by ? this.manage_by : null,
        reporting_to: this.reporting_to ? this.reporting_to : null,
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user/inactive-user',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.users = data.users

        if (this.manage_by || this.reporting_to) {
          this.users.forEach(element => {
            this.userIds.push(element.id)
          })
        }
      }
    },

    /* Reporting And Manage By Users List */
    async reportingAndManageByUser() {
      const input = {
        project_id: this.searchProjects,
      }
      const response = await this.getHTTPPostResponse(
        'timesheet-user-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.users = data.user
        this.users.push(this.userInfo)
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/vieweditheader.scss";
@import "../../assets/scss/component-css/viewedit.scss";
</style>
