<template>
  <div>
    <view-edit-header class="view-header" />
  </div>
</template>

<script>
import ViewEditHeader from './taskManage/ViewEditHeader.vue'

export default {
  components: {
    ViewEditHeader,
  },
}
</script>

  <style>
  @import "../assets/scss/component-css/viewbody.css";
  </style>
